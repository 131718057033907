.Mui-disabled {
  color: #FFF !important;
  -webkit-text-fill-color: #fff !important;
}
::-webkit-scrollbar {
  width: 1em;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: #8A7B7B;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
 
/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Passion One", monospace;
    background-color: #c1c1c1;
    color: #fff;
}


@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }

@media only screen and (max-device-width: 640px) {
  .terminal {
      position: absolute;
      width:100%;
  }
}
